import { FC } from "react";
import { useTranslation } from "react-i18next";
import SupportedSystems from "../../../constants/SupportedSystems";
import ProtectedRoute from "../../../application/auth/ProtectedRoute";
import { ConsentPaper } from "../../components";
import _useConsent from "../../../application/useConsent/useConsent";

type Props = {
  readonly useConsent?: typeof _useConsent;
};

const CreateTestOneConnectionPage: FC = ({
  useConsent = _useConsent,
}: Props) => {
  const { acceptConsent, consentIsAccepting } = useConsent();
  const { t } = useTranslation();
  const createTestOneConnection = async () => {
    if (consentIsAccepting) {
      return;
    }

    try {
      await acceptConsent(SupportedSystems.testone);
    } catch (_) {
      /* empty */
    }
  };

  const ProtectedConsentPaper = ProtectedRoute(ConsentPaper);

  return (
    <ProtectedConsentPaper
      handleNext={createTestOneConnection}
      isLoading={consentIsAccepting}
      text={{
        description1: t("consentPage.testOneDescription"),
        consentButton: t("consentPage.consent"),
        warning: t("consentPage.testEnvironmentWarning"),
      }}
    />
  );
};

export default CreateTestOneConnectionPage;

import { useParams } from 'react-router-dom';
import config from '../../../config';

const IFrameTestPage = () => {
  const params = useParams();
  const { consentId } = params;
  const iFrameUrl = `${config.BASE_URL}/consent/${consentId}`;

  return (
    <iframe
      title="My-IFRAME"
      width="100%"
      height="100%"
      src={iFrameUrl}
    />
  );
};

export default IFrameTestPage;

import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { Trans, useTranslation } from "react-i18next";
import _useConsent from "../../application/useConsent/useConsent";
import {
  ConsentSuccessPaper,
  OnboardingLayout,
  HeaderButtons,
} from "../components";
import SupportedSystems from "../../constants/SupportedSystems";
import config from "../../config";
import sharedConsts from "../../constants/SharedConsts";
import { useHeaderButtonsProps } from "./index";
import useRedirect from "../../application/auth/useRedirect";
import { ErrorPage } from "../pages";

type Props = {
  useConsent?: typeof _useConsent;
};
export const ConsentConsumedContainer = ({
  useConsent = _useConsent,
}: Props) => {
  const { consent, consentIsFetching, fetchConsentError } = useConsent();
  const { t, i18n } = useTranslation();
  const { redirect, redirectExists, redirectUrl } = useRedirect();
  const HeaderButtonsProps = useHeaderButtonsProps();

  if (consentIsFetching) return <Spinner />;
  if (consent?.source === SupportedSystems.fileone.key) {
    const url = new URL(config.FILEONE_PORTAL);
    url.searchParams.append(
      sharedConsts.languageParamKey,
      i18n.language || "en",
    );
    if (redirectExists) {
      url.searchParams.append(sharedConsts.redirectUrlKey, redirectUrl);
    }
    window.location.assign(url);
    return <Spinner />;
  }

  if (consent) {
    const { customizationSettings } = consent;
    const getThankYouMessage = () => {
      if (customizationSettings?.link || customizationSettings?.name) {
        const linkText = customizationSettings?.name ?? customizationSettings.link;
        return (
          <Trans i18nKey="consentSuccessPage.thankYouForSharingWithClient" values={{ clientName: linkText }}>
            Thanks for sharing your data with{" "}
            <a
              href={customizationSettings?.link}
              style={{ color: "#2475FC" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
            .
          </Trans>
        );
      }
      return t("consentSuccessPage.thankYouForSharing");
    };

    return (
      <OnboardingLayout
        CTA={<HeaderButtons {...HeaderButtonsProps} />}
        logoUrl={customizationSettings?.logoUrl}
        logoUrlAltText={customizationSettings?.name}
        warningText={
          consent.isTest ? t("consentPage.testEnvironmentWarning") : null
        }
      >
        <ConsentSuccessPaper
          thankYouMessage={getThankYouMessage()}
          onButtonClick={redirect}
          isButtonVisible={redirectExists}
          text={{
            thanksButton: t("common.thanks"),
            header: t("consentSuccessPage.header"),
          }}
        />
      </OnboardingLayout>
    );
  }

  return <ErrorPage consentGone={fetchConsentError?.status === 410} />;
};

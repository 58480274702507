export default class OnboardingCustomizationSettings {
  public logoUrl: string;

  public link: string;

  public name: string;

  constructor(
    logoUrl: string,
    link: string,
    name: string,
  ) {
    this.logoUrl = logoUrl;
    this.link = link;
    this.name = name;
  }

  // eslint-disable-next-line class-methods-use-this
  public Empty() {
    return new OnboardingCustomizationSettings('', '', '');
  }
}

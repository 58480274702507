import { useForm, useWatch, Controller } from "react-hook-form";
import { useState } from "react";
import {
  Spinner,
  StageBar,
  TextField,
} from "@zwapgrid/zwapgrid-ui-component-library";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import HttpRequestError from "../../../domain/exceptions/HttpRequestError";
import BIImage1 from "../../assets/BI_ConnectionPage_1.svg";
import BIImage2 from "../../assets/BI_ConnectionPage_2.svg";
import BIImage3 from "../../assets/BI_ConnectionPage_3.svg";
import styles from "./CreateBillyConnectionPage.module.scss";
import SupportedSystems from "../../../constants/SupportedSystems";
import useConsent from "../../../application/useConsent/useConsent";

type Props = {
  initialStage?: number;
};

const CreateBillyConnectionPage = ({ initialStage = 0 }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState<HttpRequestError | AxiosError | null>(
    null,
  );
  const { acceptConsent } = useConsent();
  const [stageCounter, setStageCounter] = useState(initialStage);

  const isValid = (value: string) => value && /[^\t\n\r ]/.test(value);

  const nextStage = () => setStageCounter(stageCounter + 1);
  const goToStage = (index: number) => setStageCounter(index);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      accessKey: "",
    },
  });
  const accessKey = useWatch({ control, name: "accessKey" });

  const isDisabled = (enteredAccessKey) =>
    !enteredAccessKey ||
    enteredAccessKey?.length === 0 ||
    isSubmitting ||
    !isDirty ||
    !isValid(accessKey);

  const isError = () => error != null && !isDirty;
  const createConnection = async (formData) => {
    try {
      setError(null);
      await acceptConsent(SupportedSystems.billy, formData);
    } catch (ex) {
      if (ex instanceof HttpRequestError || ex instanceof AxiosError) {
        setError(ex);
        reset((formValues) => ({ ...formValues }));
      } else throw ex;
    }
  };

  const renderStage1 = () => {
    if (stageCounter !== 0) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("billyPage.step1.header")}
        </Typography>
        <Typography>
          {t("billyPage.step1.paragraph1")}
          <br />
          <a href="https://mit.billy.dk/" target="_blank" rel="noreferrer">
            https://mit.billy.dk/login
          </a>
        </Typography>
        <Typography>{t("billyPage.step1.paragraph2")}</Typography>
        <div>
          <img
            src={BIImage1}
            className={styles.image}
            alt={t("billyPage.step1.altImageText")}
          />
        </div>
        <div className={styles.buttonAndStageBarGroup}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={nextStage}
          >
            {t("billyPage.step1.button")}
          </Button>
          <StageBar
            activeStep={stageCounter}
            steps={3}
            onStepClick={goToStage}
          />
        </div>
      </>
    );
  };

  const renderStage2 = () => {
    if (stageCounter !== 1) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("billyPage.step2.header")}
        </Typography>
        <Typography paragraph>{t("billyPage.step2.paragraph")}</Typography>
        <div>
          <img
            src={BIImage2}
            className={styles.image}
            alt={t("billyPage.step2.altImageText")}
          />
        </div>
        <div className={styles.buttonAndStageBarGroup}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={nextStage}
          >
            {t("common.next")}
          </Button>
          <StageBar
            activeStep={stageCounter}
            steps={3}
            onStepClick={goToStage}
          />
        </div>
      </>
    );
  };

  const renderStage3 = () => {
    if (stageCounter !== 2) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("billyPage.step3.header")}
        </Typography>
        <Typography paragraph>{t("billyPage.step3.paragraph")}</Typography>
        <div>
          <img
            src={BIImage3}
            className={styles.image}
            alt={t("billyPage.step3.altImageText")}
          />
        </div>
        <form className={styles.form} onSubmit={handleSubmit(createConnection)}>
          <label
            htmlFor="accessKey"
            className={styles.label}
            title={t("billyPage.step3.labelTitle")}
          >
            <Typography>{t("billyPage.step3.labelText")}</Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  name="accessKey"
                  type="password"
                  placeholder={t("billyPage.step3.inputPlaceholder")}
                  checkmark
                  color={isValid(accessKey) ? "success" : "primary"}
                  fullWidth
                  error={isError()}
                  helperText={
                    isError() &&
                    t("billyPage.connectionError", {
                      system: SupportedSystems.billy.name,
                    })
                  }
                />
              )}
              name="accessKey"
              control={control}
              defaultValue=""
            />
          </label>
          <div className={styles.buttonAndStageBarGroup}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={isDisabled(accessKey)}
            >
              {t("common.next")}
            </Button>
            <StageBar activeStep={2} steps={3} onStepClick={goToStage} />
          </div>
          {isSubmitting && <Spinner />}
        </form>
      </>
    );
  };

  return (
    <>
      {renderStage1()}
      {renderStage2()}
      {renderStage3()}
    </>
  );
};

export default CreateBillyConnectionPage;

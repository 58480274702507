import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import _authService from './services/AuthService';

const useUserRepository = (
  authService = _authService,
) => {
  const navigate = useNavigate();

  const redirectToUnauthorized = () => navigate("/unauthorized");

  const { isLoading: userIsFetching, data: user, error: fetchUserError } = useQuery({
    queryKey: ['user'],
    queryFn: () => authService.getUserData(),
    notifyOnChangeProps: ['data', 'error', 'isLoading'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    user,
    userIsFetching,
    fetchUserError,
    isAuthenticated: !!user?.isAuthenticated,
    redirectToUnauthorized,
    logout: authService.logout,
  };
};

export default useUserRepository;

import { Typography } from "@mui/material";

type ResourceNotFoundPaperProps = {
  readonly consentGone?: boolean;
  readonly text: {
    readonly altImageText: string | null;
    readonly ohNo: string | null;
    readonly somethingWrong: string | null;
    readonly consentUnavailable: string | null;
    readonly resourceUnavailable: string | null;
  };
};
export const ResourceNotFoundPaper = ({
  consentGone = false,
  text,
}: ResourceNotFoundPaperProps) => (
  <div>
    <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }}>
      {text.ohNo}
    </Typography>
    <Typography paragraph>{text.somethingWrong}</Typography>
    <Typography paragraph>
      {consentGone ? text.consentUnavailable : text.resourceUnavailable}
    </Typography>
  </div>
);

import CreateConnectionDto from './CreateConnectionDto';

export default class AcceptConsentDto {
  ConsentId: string;

  Connection: CreateConnectionDto;

  constructor(consentId: string, connection: CreateConnectionDto) {
    this.ConsentId = consentId;
    this.Connection = connection;
  }
}

import { StrictMode, Suspense } from "react";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import "./index.css";
import "@zwapgrid/zwapgrid-ui-component-library/style.css";
import {
  theme,
  LibraryThemeProvider,
  Spinner,
} from "@zwapgrid/zwapgrid-ui-component-library";
import { ThemeProvider } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import SnackbarConfig from "./constants/SnackbarConfig";
import Root from "./Root";

// import i18n (needs to be bundled ;))
import "./i18n";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LibraryThemeProvider theme={theme}>
          <SnackbarProvider {...SnackbarConfig}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <Suspense fallback={<Spinner />}>
                  <Root />
                </Suspense>
              </Router>
            </QueryClientProvider>
          </SnackbarProvider>
        </LibraryThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

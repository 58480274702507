import config from '../config';

const redirectUri = `${encodeURI(config.BASE_URL)}/connection-granted/vismadinero`;

export default {
    connectionUri: `${config.VISMADINERO.AUTH_URL}`
        + `client_id=${config.VISMADINERO.CLIENT_ID}&`
        + `redirect_uri=${redirectUri}&`
        + 'response_type=code&'
        + `scope=${config.VISMADINERO.SCOPE}&`,

    redirectUri,
};
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { useState } from "react";
import { AxiosError } from "axios";
import SupportedSystems from "../../../constants/SupportedSystems";
import { useConsent } from "../../../application/useConsent/useConsent";
import styles from "./CreateVismaNetvisorConnectionPage.module.scss";
import HttpRequestError from "../../../domain/exceptions/HttpRequestError";

type Inputs = {
  organizationIdentifier: string;
  customerIdentifier: string;
  accessKey: string;
};

const CreateVismaNetvisorConnectionPage = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<Inputs>();
  const { acceptConsent, consentIsAccepting } = useConsent();
  const [error, setError] = useState<HttpRequestError | AxiosError | null>(
    null,
  );

  const hasError = () => !!error && !isDirty;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (consentIsAccepting) {
      return;
    }

    try {
      setError(null);
      await acceptConsent(SupportedSystems.vismanetvisor, data);
    } catch (ex) {
      if (ex instanceof HttpRequestError || ex instanceof AxiosError) {
        setError(ex);
        reset((formValues) => ({ ...formValues }));
      } else {
        throw ex;
      }
    }
  };

  return (
    <>
      <Typography variant="h6">{t("vismaNetvisor.header")}</Typography>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="organizationIdentifier">
          <Typography>{t("vismaNetvisor.orgIdLabel")}</Typography>
          <TextField
            id="organizationIdentifier"
            required
            error={hasError()}
            {...register("organizationIdentifier", { required: true })}
          />
        </label>

        <label htmlFor="customerIdentifier">
          <Typography>{t("vismaNetvisor.customerIdLabel")}</Typography>
          <TextField
            id="customerIdentifier"
            required
            error={hasError()}
            {...register("customerIdentifier", { required: true })}
          />
        </label>

        <label htmlFor="accessKey">
          <Typography>{t("vismaNetvisor.accessKeyLabel")}</Typography>
          <TextField
            id="accessKey"
            required
            error={hasError()}
            type="password"
            {...register("accessKey", { required: true })}
            helperText={
              hasError() &&
              t("common.connectionError", {
                system: SupportedSystems.vismanetvisor.name,
              })
            }
          />
        </label>

        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!isValid || consentIsAccepting || hasError()}
        >
          {t("common.next")}
        </Button>
      </form>
    </>
  );
};

export default CreateVismaNetvisorConnectionPage;

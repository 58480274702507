import {
  ChangeEventHandler,
  forwardRef,
  KeyboardEventHandler,
  Ref,
} from "react";
import styles from "./SystemButton.module.scss";
import { SupportedSystem } from "../../../constants/SupportedSystems";

type SystemButtonProps = {
  value: string;
  system: SupportedSystem;
  checked: boolean;
  onChange: (value: string) => void;
  name?: string;
  disabled?: boolean;
};
// Either disable this or disable eslint-prefer-arrow-callback
// eslint-disable-next-line react/display-name
export const SystemButton = forwardRef(
  (
    {
      value,
      system,
      checked = false,
      onChange,
      name = "SystemButton",
      disabled = false,
    }: SystemButtonProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleKeyDown: KeyboardEventHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onChange(value);
      }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      onChange(event.target.value);
    };

    return (
      <label
        className={styles.sourceElement}
        key={value}
        htmlFor={value}
        aria-disabled={disabled}
        title={`Select ${system.name}`}
      >
        <input
          ref={ref}
          id={value}
          type="checkbox"
          title={`${system.name} checkbox`}
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <img src={system.logoUrl} alt={`${system.name} Logo`} />
      </label>
    );
  },
);

export default SystemButton;

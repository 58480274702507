import config from '../config';

const redirectUri = `${encodeURI(config.BASE_URL)}/connection-granted/fortnox`;

export default {
  connectionUri: 'https://apps.fortnox.se/oauth-v1/auth?'
+ `client_id=${config.FORTNOX.CLIENT_ID}&`
+ `redirect_uri=${redirectUri}&`
+ `scope=${config.FORTNOX.SCOPE}&`
+ 'state=NA&'
+ 'response_type=code&',

  redirectUri,
};

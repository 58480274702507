import { Typography } from "@mui/material";
import classNames from "classnames";
import styles from "./OnboardingLayout.module.scss";

type OnboardingLayoutProps = {
  children: React.ReactNode;
  logoUrl: string | null | undefined;
  CTA?: React.ReactNode;
  logoUrlAltText?: string | null;
  className?: string;
  containerClassName?: string;
  warningText?: string | null;
};
export const OnboardingLayout = ({
  children,
  logoUrl,
  CTA,
  logoUrlAltText,
  className,
  containerClassName,
  warningText,
}: OnboardingLayoutProps) => (
  <main
    className={classNames(styles.layoutWrap, containerClassName, {
      [styles.withCta]: !!CTA,
    })}
  >
    {CTA}
    <div className={styles.onboardingPanelWrap}>
      {warningText ? (
        <div className={styles.warningStripe}>
          <Typography paragraph>{warningText}</Typography>
        </div>
      ) : null}
      <div className={styles.borderBox}>
        <div className={styles.clientLogo}>
          {logoUrl && <img src={logoUrl} alt={logoUrlAltText ?? "Logo"} />}
        </div>
        <div className={classNames(styles.content, className)}>{children}</div>
      </div>
    </div>
  </main>
);

OnboardingLayout.defaultProps = {
  logoUrlAltText: "",
};

export default OnboardingLayout;

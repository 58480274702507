import { useState } from 'react';
import SupportedSystems, { SupportedSystem } from '../../constants/SupportedSystems';
import vismaConnectionInformation from '../../constants/VismaEAccountingConnectionInformation';
import fortnoxConnectionInformation from '../../constants/FortnoxConnectionInformation';
import procountorConnectionInformation from '../../constants/ProcountorConnectionInformation';
import vismaEConomicConnectionInformation from '../../constants/VismaEConomicConnectionInformation';
import vismaDineroConnectionInformation from "../../constants/VismaDineroConnectionInformation";

export function useOpenSystemWindow() {
  const [isWindowOpened, setIsWindowOpened] = useState(false);

  const getRedirectUri = (system: SupportedSystem) => {
    switch (system.key) {
      case (SupportedSystems.fortnox.key):
        return fortnoxConnectionInformation.redirectUri;
      case (SupportedSystems.vismaeaccounting.key):
        return vismaConnectionInformation.redirectUri;
      case (SupportedSystems.procountor.key):
        return procountorConnectionInformation.redirectUri;
      case (SupportedSystems.vismaeconomic.key):
        return vismaEConomicConnectionInformation.redirectUri;
      case (SupportedSystems.vismadinero.key):
          return vismaDineroConnectionInformation.redirectUri;
      default:
        return null;
    }
  };

  const openWindow = (connectionUri: string) => window.open(
    connectionUri,
    '_blank',
    'menubar=no,location=yes,center-screen=yes,personalbar=no,status=no,dependent=yes,width=700,height=1250',
  );

  const openSystemWindow = (system: SupportedSystem) => {
    switch (system.key) {
      case (SupportedSystems.fortnox.key):
        return openWindow(fortnoxConnectionInformation.connectionUri);
      case (SupportedSystems.vismaeaccounting.key):
        return openWindow(vismaConnectionInformation.connectionUri);
      case (SupportedSystems.procountor.key):
        return openWindow(procountorConnectionInformation.connectionUri);
      case (SupportedSystems.vismaeconomic.key):
        return openWindow(vismaEConomicConnectionInformation.connectionUri);
      case (SupportedSystems.vismadinero.key):
        return openWindow(vismaDineroConnectionInformation.connectionUri);
      default:
        return null;
    }
  };

  const openConnectingWindow = (system: SupportedSystem, handleAuthCode: ((formData) => Promise<void>)) => {
    if (isWindowOpened) return;

    const authWindow = openSystemWindow(system);
    if (authWindow === null) return;

    setIsWindowOpened(true);
    const handleConnectionMessage = async (event: MessageEvent) => {
      if (event.data?.type === 'error') {
        authWindow.close();
        return;
      }

      if (event.data?.type !== 'connection') return;
      authWindow.close();

      window.removeEventListener('message', handleConnectionMessage);
      await handleAuthCode({
        authorizationCode: event.data.authorizationCode,
        accessToken: event.data.accessToken,
        redirectUri: getRedirectUri(system),
      });
    };

    const checkOpenTimer = setInterval(() => {
      if (!authWindow.closed) return;

      window.removeEventListener('message', handleConnectionMessage);
      setIsWindowOpened(false);
      clearInterval(checkOpenTimer);
    }, 500);
    window.addEventListener('message', handleConnectionMessage);
  };

  return { openConnectingWindow, isWindowOpened };
}

export default useOpenSystemWindow;

import { useTranslation } from "react-i18next";
import useAuth from "../../application/auth/useAuth";
import { HeaderButtons } from "../components";

export const useHeaderButtonsProps = () => {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, logoutWithRedirect } = useAuth();
  return {
    activeLanguage: i18n.resolvedLanguage ?? "en",
    onLanguageChange: i18n.changeLanguage,
    onLogout: logoutWithRedirect,
    isAuthenticated,
    text: {
      changeLanguage: t("common.changeLanguage"),
      logout: t("common.logout"),
    },
  };
};
const Wrapper = () => {
  const props = useHeaderButtonsProps();
  return <HeaderButtons {...props} />;
};

export default Wrapper;

import {
  RenderFlagIcon,
  SelectLanguagePopper,
  SelectLanguageModalContent,
} from "@zwapgrid/zwapgrid-ui-component-library";
import {
  Button,
  Dialog,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./HeaderButtons.module.scss";

export type HeaderButtonProps = {
  readonly activeLanguage: string;
  readonly onLanguageChange: (language: string) => void;
  readonly onLogout: () => void;
  readonly isAuthenticated: boolean;
  readonly text: {
    readonly changeLanguage: string;
    readonly logout: string;
  };
};
export const HeaderButtons = ({
  onLanguageChange,
  activeLanguage,
  onLogout,
  isAuthenticated,
  text,
}: HeaderButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const renderLogout = () => {
    if (!isAuthenticated) {
      return null;
    }
    return <Button onClick={onLogout}>{text.logout}</Button>;
  };
  return (
    <>
      <div className={styles.CTAButtons}>
        <SelectLanguagePopper
          availableLanguages={["en", "sv"]}
          activeLanguage={activeLanguage}
          onLanguageChange={onLanguageChange}
        />
        {renderLogout()}
      </div>
      <SpeedDial
        ariaLabel="Menu"
        direction="down"
        className={styles.speedDial}
        icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<CloseIcon />} />}
      >
        <SpeedDialAction
          onClick={() => setDialogOpen(true)}
          icon={<RenderFlagIcon activeLanguage={activeLanguage} />}
          tooltipTitle={text.changeLanguage}
        />
        {isAuthenticated && (
          <SpeedDialAction
            onClick={onLogout}
            icon={<LogoutIcon />}
            tooltipTitle={text.logout}
          />
        )}
      </SpeedDial>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        disableRestoreFocus
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <SelectLanguageModalContent
          availableLanguages={["en", "sv"]}
          activeLanguage={activeLanguage}
          onLanguageChange={onLanguageChange}
          onClose={() => setDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default HeaderButtons;

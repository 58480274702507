import { Button, Typography } from "@mui/material";
import { ReactNode } from "react";
import styles from "./ConsentSuccessPaper.module.scss";

type Props = {
  readonly thankYouMessage: ReactNode;
  readonly onButtonClick: () => void;
  readonly isButtonVisible: boolean;
  text: {
    readonly thanksButton: string;
    readonly header: string;
  };
};
export function ConsentSuccessPaper({
  thankYouMessage,
  onButtonClick,
  isButtonVisible,
  text,
}: Props) {
  return (
    <div className={styles.container}>
      <Typography
        variant="h4"
        component="h1"
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        {text.header}
      </Typography>
      <Typography variant="h6" component="h2" sx={{ textAlign: "center" }}>
        {thankYouMessage}
      </Typography>

      {isButtonVisible ? (
        <Button
          type="submit"
          variant="contained"
          size="large"
          onClick={onButtonClick}
          fullWidth
        >
          {text.thanksButton}
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
}

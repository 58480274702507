import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, StageBar } from "@zwapgrid/zwapgrid-ui-component-library";
import { Button, Typography } from "@mui/material";

import _useConsent from "../../../application/useConsent/useConsent";
import SupportedSystems from "../../../constants/SupportedSystems";

import InstructionImageStep1 from "../../assets/Visma-e-conomic_ConnectionPage_1.png";
import InstructionImageStep2 from "../../assets/Visma-e-conomic_ConnectionPage_2.png";
import styles from "./CreateVismaEconomicConnectionPage.module.scss";

type Props = {
  readonly useConsent?: typeof _useConsent;
  readonly initStepNumber?: number;
};

function CreateVismaEconomicConnectionPage({
  useConsent = _useConsent,
  initStepNumber = 0,
}: Props) {
  const {
    consentIsAccepting,
    consentAcceptingFailed,
    openSystemConnectingWindow,
    connectingWindowOpened,
  } = useConsent();
  const { t } = useTranslation();
  const [stepNumber, setStepNumber] = useState(initStepNumber);

  const goToNextStep = () =>
    setStepNumber((oldStepNumber) => oldStepNumber + 1);
  const goToStep = (index: number) => setStepNumber(index);

  const renderStageBar = () => (
    <StageBar activeStep={stepNumber} steps={3} onStepClick={goToStep} />
  );

  const renderStep1 = () => {
    if (stepNumber !== 0) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("vismaEconomic.step1.header")}
        </Typography>
        <Typography paragraph>
          {t("vismaEconomic.step1.paragraph1")}
          <br />
          <a
            href="https://secure.e-conomic.com/home"
            target="_blank"
            rel="noreferrer"
          >
            https://secure.e-conomic.com/home
          </a>
        </Typography>
        <Typography paragraph>{t("vismaEconomic.step1.paragraph2")}</Typography>
        <div className={styles.imageContainer}>
          <img
            src={InstructionImageStep1}
            alt={t("vismaEconomic.step2.altImageText")}
          />
        </div>
        <div className={styles.buttonAndStageBarGroup}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={goToNextStep}
          >
            {t("vismaEconomic.step1.button")}
          </Button>
          {renderStageBar()}
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    if (stepNumber !== 1) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("vismaEconomic.step2.header")}
        </Typography>
        <Typography paragraph>{t("vismaEconomic.step2.paragraph")}</Typography>
        <div className={styles.imageContainer}>
          <img
            src={InstructionImageStep2}
            alt={t("vismaEconomic.step2.altImageText")}
          />
        </div>
        <div className={styles.buttonAndStageBarGroup}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={goToNextStep}
          >
            {t("common.next")}
          </Button>
          {renderStageBar()}
        </div>
      </>
    );
  };

  const renderError = () => {
    if (!consentAcceptingFailed) return null;

    return (
      <Typography paragraph className={styles.error}>
        {t("vismaEconomic.connectionError", {
          system: SupportedSystems.vismaeconomic.name,
        })}
      </Typography>
    );
  };

  const renderStep3 = () => {
    if (stepNumber !== 2) return null;
    return (
      <>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ fontWeight: "bold" }}
        >
          {t("vismaEconomic.step3.header")}
        </Typography>
        <Typography paragraph>{t("vismaEconomic.step3.paragraph")}</Typography>
        {renderError()}
        <div className={styles.buttonAndStageBarGroup}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() =>
              openSystemConnectingWindow(SupportedSystems.vismaeconomic)
            }
            disabled={consentIsAccepting || connectingWindowOpened}
          >
            {t("vismaEconomic.step3.button")}
          </Button>
          {renderStageBar()}
        </div>
        {(consentIsAccepting || connectingWindowOpened) && <Spinner />}
      </>
    );
  };

  return (
    <>
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}
    </>
  );
}

export default CreateVismaEconomicConnectionPage;

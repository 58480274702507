import _useConsentRepository from '../../infrastructure/consent.repository';
import _useConnections from '../useSystems/useConnections';
import SupportedSystems, { SupportedSystem } from '../../constants/SupportedSystems';
import CreateConnectionDto from '../../infrastructure/dtos/CreateConnectionDto';
import OnboardingCustomizationSettings from '../../domain/models/OnboardingCustomizationSettings';

export const useConsent = (
  useConsentRepository = _useConsentRepository,
  useConnections = _useConnections,
) => {
  const {
    requestedConsentId,
    consent,
    consentIsFetching,
    fetchConsentError,
    consentIsAccepting,
    consentAcceptingFailed,
    acceptConsent: acceptConsentInternal,
  } = useConsentRepository();
  const { openConnectingWindow, isWindowOpened } = useConnections();

  function getSystem(systemKey:string | undefined):SupportedSystem | undefined {
    if (!systemKey) return undefined;
    return SupportedSystems[systemKey.toLocaleLowerCase()];
  }

  const hasConnectingWindow = (system: SupportedSystem): boolean => system.key === SupportedSystems.fortnox.key
      || system.key === SupportedSystems.vismaeaccounting.key
      || system.key === SupportedSystems.procountor.key
      || system.key === SupportedSystems.vismaeconomic.key
      || system.key === SupportedSystems.vismadinero.key;

  const customizationSettings = consent?.customizationSettings
    ?? new OnboardingCustomizationSettings('', '', 'Test Client (Please set it up!)');

  const acceptConsent = async (system: SupportedSystem, formData?) => {
    const connection = {
      name: system.name,
      source: system.key,
      ...formData,
    } as CreateConnectionDto;

    return acceptConsentInternal(connection);
  };

  function openSystemConnectingWindow(system:SupportedSystem) {
    if (!hasConnectingWindow(system)) {
      return;
    }

    openConnectingWindow(system, async (formData) => acceptConsent(system, formData));
  }

  return {
    requestedConsentId,
    consent,
    customizationSettings,
    consentIsFetching,
    fetchConsentError,
    consentIsAccepting,
    consentAcceptingFailed,
    openSystemConnectingWindow,
    acceptConsent,
    getSystem,
    connectingWindowOpened: isWindowOpened,
  };
};

export default useConsent;

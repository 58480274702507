import { RawAxiosRequestConfig } from 'axios';
import httpClient from '../httpClient';
import Consent from '../../domain/models/Consent';

async function getConsent(
  consentId: string,
  signal:AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<Consent> {
  const url = `/api/consents/${consentId}`;
  const requestOptions = {
    allowAnonymous: true,
    errorHandler,
    signal,
    hideErrorMessage: true,
  };
  const response = await httpClient.instance.get<Consent>(url, requestOptions);
  return response.data;
}

async function acceptConsent(
  data,
  etag,
) {
  const url = `/api/consents`;
  const response = await httpClient.instance.post(url, data, {
    headers: {
      'If-Match': etag,
    },
  } as RawAxiosRequestConfig);
  return response.data;
}

export default {
  getConsent,
  acceptConsent,
};

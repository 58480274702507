import useUserRepository from "../../infrastructure/user.repository";
import useRedirect from "./useRedirect";

const useAuth = () => {
  const userRepository = useUserRepository();
  const { redirectUrl } = useRedirect();

  const logoutWithRedirect = () => userRepository.logout(redirectUrl);

  return { ...userRepository, logoutWithRedirect };
};

export default useAuth;

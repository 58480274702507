import { FC } from "react";
import { useTranslation } from "react-i18next";
import SupportedSystems from "../../../constants/SupportedSystems";
import config from "../../../config";
import ProtectedRoute from "../../../application/auth/ProtectedRoute";
import { ConsentPaper } from "../../components";
import useConsent from "../../../application/useConsent/useConsent";
import sharedConsts from "../../../constants/SharedConsts";

const CreateFileOneConnectionPage: FC = () => {
  const {
    acceptConsent,
    consentIsAccepting,
    consent,
    customizationSettings,
  } = useConsent();
  const { i18n, t } = useTranslation();

  const createFileOneConnectionAndRedirect = async () => {
    if (consentIsAccepting) {
      return;
    }

    try {
      await acceptConsent(SupportedSystems.fileone);
      const url = new URL(config.FILEONE_PORTAL);
      url.searchParams.append(
        sharedConsts.languageParamKey,
        i18n.language || "en",
      );
      window.location.assign(url);
    } catch (_) {
      /* empty */
    }
  };

  const ProtectedConsentPaper = ProtectedRoute(ConsentPaper);

  return (
    <ProtectedConsentPaper
      handleNext={createFileOneConnectionAndRedirect}
      isLoading={consentIsAccepting}
      text={{
        description1: t("consentPage.description1", {
          client: customizationSettings.name,
          system: SupportedSystems.fileone.name,
        }),
        description2: t("consentPage.description2", {
          client: customizationSettings.name,
        }),
        consentButton: t("consentPage.consent"),
        warning: consent?.isTest
          ? t("consentPage.testEnvironmentWarning")
          : null,
      }}
    />
  );
};

export default CreateFileOneConnectionPage;

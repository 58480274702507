import config from '../config';

const redirectUri = `${config.BASE_URL}/connection-granted/vismaeaccounting`;

export default {
  connectionUri: `${config.VISMAEACCOUNTING.AUTH_URL}`
+ `client_id=${config.VISMAEACCOUNTING.CLIENT_ID}&`
+ `redirect_uri=${redirectUri}&`
+ 'response_type=code&'
+ 'prompt=login&'
+ `scope=${config.VISMAEACCOUNTING.SCOPE}&`
+ `state=${config.BASE_URL}`,

  redirectUri,
};

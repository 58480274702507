import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import {
  ConsentPaper,
  SelectSystemPaper,
  OnboardingLayout,
  HeaderButtons,
} from "../../components";
import CreateBjornLundenConnectionPage from "../CreateBjornLundenConnectionPage";
import CreateFileOneConnectionPage from "../CreateFileOneConnectionPage/CreateFileOneConnectionPage";
import CreateVismaEconomicConnectionPage from "../CreateVismaEconomicConnectionPage";
import { ConsentContainer, useHeaderButtonsProps } from "../../containers";
import { ConsentConsumedContainer } from "../../containers/ConsentConsumedContainer";

import ProtectedRoute from "../../../application/auth/ProtectedRoute";
import useConsent from "../../../application/useConsent/useConsent";
import SupportedSystems, {
  SupportedSystem,
} from "../../../constants/SupportedSystems";

import ConsentStatus from "../../../domain/models/ConsentStatus";
import CreateTestOneConnectionPage from "../CreateTestOneConnectionPage/CreateTestOneConnectionPage";
import CreateBillyConnectionPage from "../CreateBillyConnectionPage";
import CreateVismaNetvisorConnectionPage from "../CreateVismaNetvisorConnectionPage/CreateVismaNetvisorConnectionPage";

export const OnboardingPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    consent,
    consentIsFetching,
    getSystem,
    customizationSettings,
    consentIsAccepting,
    connectingWindowOpened,
    openSystemConnectingWindow,
  } = useConsent();
  const headerButtonProps = useHeaderButtonsProps();

  const ProtectedConsentConsumedContainer = ProtectedRoute(
    ConsentConsumedContainer,
  );
  const ProtectedConsentPaper = ProtectedRoute(ConsentPaper);
  const ProtectedLayout = ProtectedRoute(OnboardingLayout);

  // Check if requested system is supported
  const { systemKey } = params;
  const requestedSystem = getSystem(systemKey);

  const description1 = t("consentPage.description1", {
    client: customizationSettings.name,
    system: requestedSystem?.name,
  });

  const description2 = t("consentPage.description2", {
    client: customizationSettings.name,
    system: requestedSystem?.name,
  });

  const ProtectedCreateBjornLundenConnectionPage = ProtectedRoute(
    ConsentContainer({
      component: <CreateBjornLundenConnectionPage />,
      text: {
        description1,
        description2,
        consentButton: t("consentPage.consent"),
        warningText: consent?.isTest
          ? t("consentPage.testEnvironmentWarning")
          : null,
      },
    }),
  );
  const ProtectedCreateVismaEconomicConnectionPage = ProtectedRoute(
    ConsentContainer({
      component: <CreateVismaEconomicConnectionPage />,
      text: {
        description1,
        description2,
        consentButton: t("consentPage.consent"),
        warningText: consent?.isTest
          ? t("consentPage.testEnvironmentWarning")
          : null,
      },
    }),
  );

  const ProtectedBillyConnectionPage = ProtectedRoute(
    ConsentContainer({
      component: <CreateBillyConnectionPage />,
      text: {
        description1,
        description2,
        consentButton: t("consentPage.consent"),
        warningText: consent?.isTest
          ? t("consentPage.testEnvironmentWarning")
          : null,
      },
    }),
  );

  const ProtectedVismaNetvisorConnectionPage = ProtectedRoute(
    ConsentContainer({
      component: <CreateVismaNetvisorConnectionPage />,
      text: {
        description1,
        description2,
        consentButton: t("consentPage.consent"),
        warningText: consent?.isTest
          ? t("consentPage.testEnvironmentWarning")
          : null,
      },
    }),
  );

  // Load consent
  if (consentIsFetching) return <Spinner />;

  // Check if consent is consumed
  if (!consent || consent.status !== ConsentStatus.Created) {
    return <ProtectedConsentConsumedContainer />;
  }

  const handleSystemSelect = (system: string) => {
    let url = location.pathname;
    if (systemKey) {
      url = `${url.substring(0, url.lastIndexOf("/"))}/${system}`;
    } else {
      url += url.endsWith("/") ? system : `/${system}`;
    }
    url += location.search;
    navigate(url);
  };

  const getPageContent = (system: SupportedSystem) => {
    const handleNext = () => {
      openSystemConnectingWindow(system);
    };

    switch (system.key) {
      case SupportedSystems.fileone.key:
        return <CreateFileOneConnectionPage />;
      case SupportedSystems.testone.key:
        return <CreateTestOneConnectionPage />;
      case SupportedSystems.vismaeconomic.key:
        return <ProtectedCreateVismaEconomicConnectionPage />;
      case SupportedSystems.bjornlunden.key:
        return <ProtectedCreateBjornLundenConnectionPage />;
      case SupportedSystems.billy.key:
        return <ProtectedBillyConnectionPage />;
      case SupportedSystems.vismanetvisor.key:
        return <ProtectedVismaNetvisorConnectionPage />;
      default:
        return (
          <ProtectedConsentPaper
            text={{
              description1,
              description2,
              consentButton: t("consentPage.consent"),
              warning: consent?.isTest
                ? t("consentPage.testEnvironmentWarning")
                : null,
            }}
            handleNext={handleNext}
            isLoading={consentIsAccepting || connectingWindowOpened}
          />
        );
    }
  };

  if (!requestedSystem || !consent.systems.includes(requestedSystem.key)) {
    return (
      <OnboardingLayout
        CTA={<HeaderButtons {...headerButtonProps} />}
        logoUrl={customizationSettings.logoUrl}
        logoUrlAltText={customizationSettings.name}
        warningText={
          consent.isTest ? t("consentPage.testEnvironmentWarning") : null
        }
      >
        <SelectSystemPaper
          handleNext={handleSystemSelect}
          systems={consent.systems}
          text={{
            logo: t("common.logo"),
            choseSystem: t("selectSystemPage.chooseSystem"),
            nextButton: t("common.next"),
          }}
        />
      </OnboardingLayout>
    );
  }

  return (
    <ProtectedLayout
      CTA={<HeaderButtons {...headerButtonProps} />}
      logoUrl={customizationSettings.logoUrl}
      logoUrlAltText={customizationSettings.name}
      warningText={
        consent.isTest ? t("consentPage.testEnvironmentWarning") : null
      }
    >
      {getPageContent(requestedSystem)}
    </ProtectedLayout>
  );
};

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import styles from "./App.module.scss";
import HeaderButtons from "../../containers/HeaderButtons.wrapper";

export const App = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.appContainer}>
      <HeaderButtons />
      <Button
        variant="contained"
        size="large"
        onClick={() => window.history.back()}
      >
        {t("common.goBack")}
      </Button>
    </div>
  );
};

export default App;

import { useSnackbar } from "notistack";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";

import httpClient from "./infrastructure/httpClient";
import ProtectedRoute from "./application/auth/ProtectedRoute";

import useAuth from "./application/auth/useAuth";
import {
  App,
  UnauthorizedPage,
  ConnectionCreationAcceptedPage,
  IFrameTestPage,
  OnboardingPage,
} from "./presentation/pages";

const Root = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userIsFetching } = useAuth();

  httpClient.setup(enqueueSnackbar);

  if (userIsFetching) {
    return <Spinner />;
  }

  const ProtectedApp = ProtectedRoute(App);
  const renderRoutes = () => (
    <Routes>
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route
        path="/connection-granted/:systemKey"
        element={<ConnectionCreationAcceptedPage />}
      />
      <Route
        path="/:systemKey?"
        element={<OnboardingPage />}
      />
      <Route path="/iframetest/:consentId" element={<IFrameTestPage />} />
      <Route path="*" element={<ProtectedApp />} />
    </Routes>
  );

  return renderRoutes();
};

export default Root;

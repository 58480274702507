import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const ConnectionCreationAccepted = () => {
  const [searchParams] = useSearchParams();
  const { systemKey } = useParams();

  useEffect(() => {
    if (systemKey?.toLocaleLowerCase() === 'fortnox'
      || systemKey?.toLocaleLowerCase() === 'vismaeaccounting'
      || systemKey?.toLowerCase() === 'procountor'
      || systemKey?.toLowerCase() === 'vismaeconomic'
      || systemKey?.toLowerCase() === 'vismadinero') {
      const errorMessage = searchParams.get('error');
      const data = {
        type: !errorMessage ? 'connection' : 'error',
        errorMessage,
        authorizationCode: searchParams.get('code'),
        accessToken: searchParams.get('token'),
      };
      window.opener.postMessage(data);
    }
  });

  return (
    <div />
  );
};

export default ConnectionCreationAccepted;

import config from '../config';

const redirectUri = `${config.BASE_URL}/connection-granted/vismaeconomic`;

export default {
  connectionUri: 'https://secure.e-conomic.com/secure/api1/requestaccess.aspx?'
        + `appPublicToken=${config.VISMAECONOMIC.CLIENT_ID}&`
        + `redirectUrl=${redirectUri}&`
        + `state=${config.BASE_URL}`,

  redirectUri,
};

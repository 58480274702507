import AcceptConsentDto from './AcceptConsentDto';

export default class AcceptConsentMutationDto {
  data: AcceptConsentDto;

  etag: string;

  constructor(consent: AcceptConsentDto, etag: string) {
    this.data = consent;
    this.etag = etag;
  }
}

import { useTranslation } from "react-i18next";
import {
  HeaderButtons,
  OnboardingLayout,
  ResourceNotFoundPaper,
} from "../../components";
import { useHeaderButtonsProps } from "../../containers";

export const ErrorPage = ({ consentGone = false }) => {
  const { t } = useTranslation();
  const headerButtonsProps = useHeaderButtonsProps();
  return (
    <OnboardingLayout
      CTA={<HeaderButtons {...headerButtonsProps} />}
      logoUrl="/assets/zwapbot.png"
      logoUrlAltText={t("404Pages.altImageText")}
    >
      <ResourceNotFoundPaper
        text={{
          altImageText: t("404Pages.altImageText"),
          ohNo: t("404Pages.ohNo"),
          somethingWrong: t("404Pages.somethingWrong"),
          consentUnavailable: t("404Pages.consentUnavailable"),
          resourceUnavailable: t("404Pages.resourceUnavailable"),
        }}
        consentGone={consentGone}
      />
    </OnboardingLayout>
  );
};

import httpClient from '../httpClient';

const logout = (redirectUrl: string | null = null) => {
  window.location.href = `/auth/logout?redirectUrl=${redirectUrl ?? ''}`;
};

const getUserData = async () => {
  const url = '/auth/getUser';
  const response = await httpClient.instance.get(url);
  return response.data;
};

export default {
  logout,
  getUserData,
};

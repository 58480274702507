import _useOpenSystemWindow from './useOpenSystemWindow';

export function useConnections(
  useOpenSystemWindow = _useOpenSystemWindow,
) {
  const { isWindowOpened, openConnectingWindow } = useOpenSystemWindow();

  return {
    isWindowOpened,
    openConnectingWindow,
  };
}

export default useConnections;

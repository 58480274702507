export type SupportedSystem = {
  key: string;
  name: string;
  logoUrl: string;
};
export const SupportedSystems = {
  fortnox: {
    key: "Fortnox",
    name: "Fortnox",
    logoUrl: "/assets/logos/fortnox.svg",
  } as SupportedSystem,
  vismaeaccounting: {
    key: "VismaEAccounting",
    name: "Visma eAccounting",
    logoUrl: "/assets/logos/visma.png",
  } as SupportedSystem,
  bjornlunden: {
    key: "BjornLunden",
    name: "Bjorn Lunden",
    logoUrl: "/assets/logos/bjorn-lunden.svg",
  } as SupportedSystem,
  fileone: {
    key: "FileOne",
    name: "File.1",
    logoUrl: "/assets/logos/upload-files.svg",
  } as SupportedSystem,
  procountor: {
    key: "Procountor",
    name: "Procountor",
    logoUrl: "/assets/logos/procountor.svg",
  } as SupportedSystem,
  vismaeconomic: {
    key: "VismaEConomic",
    name: "Visma e‑conomic",
    logoUrl: "/assets/logos/visma-e-conomic.png",
  } as SupportedSystem,
  testone: {
    key: "TestOne",
    name: "Test.1",
    logoUrl: "/assets/logos/testone.svg",
  } as SupportedSystem,
  billy: {
    key: "Billy",
    name: "Billy",
    logoUrl: "/assets/logos/billy.svg",
  } as SupportedSystem,
  vismadinero: {
    key: "VismaDinero",
    name: "Visma Dinero",
    logoUrl: "/assets/logos/visma-dinero.svg",
  } as SupportedSystem,
  vismanetvisor: {
    key: "VismaNetvisor",
    name: "Visma Netvisor",
    logoUrl: "/assets/logos/visma-netvisor.svg",
  } as SupportedSystem,
};
export default SupportedSystems;

import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { Button, Typography } from "@mui/material";

import styles from "./ConsentPaper.module.scss";

type Props = {
  readonly handleNext: () => void;
  readonly isLoading?: boolean;
  readonly text: {
    readonly warning?: string | null;
    readonly description1: string | null;
    readonly description2?: string | null;
    readonly consentButton: string | null;
  };
};

export function ConsentPaper({ handleNext, isLoading = false, text }: Props) {
  return (
    <>
      <div className={styles.wrapper}>
        {text.description1 && (
          <Typography paragraph>{text.description1}</Typography>
        )}
        {text.description2 && (
          <Typography paragraph>{text.description2}</Typography>
        )}
      </div>
      <Button
        type="submit"
        variant="contained"
        size="large"
        onClick={handleNext}
        disabled={isLoading}
      >
        {text.consentButton}
      </Button>
      {isLoading && <Spinner />}
    </>
  );
}

import { useState } from "react";
import { ConsentPaper } from "../components";
import { useConsent } from "../../application/useConsent/useConsent";

type Props = {
  component: JSX.Element;
  text: {
    description1: string | null;
    description2?: string | null;
    warningText?: string | null;
    consentButton: string | null;
  };
};
export const ConsentContainer = ({ component, text }: Props) =>
  function WrapperInternal() {
    const [showConsentPaper, setShowConsentPaper] = useState(true);
    const removeConsentPaper = () => setShowConsentPaper(false);
    const { consent, consentIsAccepting, connectingWindowOpened } =
      useConsent();
    if (!consent) return null;

    if (showConsentPaper) {
      return (
        <ConsentPaper
          text={text}
          handleNext={removeConsentPaper}
          isLoading={consentIsAccepting || connectingWindowOpened}
        />
      );
    }
    return component;
  };

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import _useAuth from '../application/auth/useAuth';
import consentService from './services/ConsentService';
import HttpRequestError from '../domain/exceptions/HttpRequestError';
import AcceptConsentMutationDto from "./dtos/AcceptConsentMutationDto";
import Consent from "../domain/models/Consent";
import ConsentStatus from "../domain/models/ConsentStatus";
import CreateConnectionDto from "./dtos/CreateConnectionDto";
import AcceptConsentDto from "./dtos/AcceptConsentDto";

export const consentQueryKey = 'consent';

const acceptConsentMutationKey = "ACCEPT_CONSENT_MUTATION";

const useAcceptConsent = (consent) => {
  const queryClient = useQueryClient();

  const acceptConsentMutation = useMutation({
    mutationKey: [acceptConsentMutationKey],
    mutationFn: (parameters: AcceptConsentMutationDto) =>
      consentService.acceptConsent(
        parameters.data,
        parameters.etag,
      ),
    onSuccess: async () => {
      queryClient.setQueryData(
        [consentQueryKey, consent.id],
        (oldData: Consent) => ({
          ...oldData,
          status: ConsentStatus.Accepted,
        }),
      );
      await queryClient.refetchQueries({
        queryKey: [consentQueryKey, consent.id],
      });
    },
  });

  async function acceptConsent(connection: CreateConnectionDto) {
    if (!consent || acceptConsentMutation.isSuccess) {
      return null;
    }

    return acceptConsentMutation.mutateAsync(
      new AcceptConsentMutationDto(
        new AcceptConsentDto(consent.id, connection),
        consent.etag,
      ),
    );
  }

  return {
    acceptConsent,
    consentIsAccepting: acceptConsentMutation.isPending,
    consentAcceptingFailed: acceptConsentMutation.isError,
  };
};

const useLoadConsent = (consentId) => {
  const { isLoading, data, error } = useQuery({
    queryKey: [consentQueryKey, consentId],
    queryFn: () => consentService.getConsent(consentId as string),
    enabled: !!consentId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    consentIsFetching: isLoading,
    consent: data,
    fetchConsentError: error as HttpRequestError,
  };
};

const useConsentRepository = (useAuth = _useAuth) => {
  const { user } = useAuth();
  const consentId = user?.consentId;
  const consentData = useLoadConsent(consentId);
  const { consent } = consentData;
  const { acceptConsent, consentIsAccepting, consentAcceptingFailed } = useAcceptConsent(consent);

  return {
    requestedConsentId: consentId,
    ...consentData,
    consentIsAccepting,
    consentAcceptingFailed,
    acceptConsent,
  };
};

export default useConsentRepository;

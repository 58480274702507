import config from '../config';

const redirectUri = `${config.BASE_URL}/connection-granted/procountor`;

export default {
  connectionUri: `${config.PROCOUNTOR.AUTH_URL}`
        + 'response_type=code&'
        + `client_id=${config.PROCOUNTOR.CLIENT_ID}&`
        + `redirect_uri=${redirectUri}&`
        + `state=${config.BASE_URL}`,

  redirectUri,
};

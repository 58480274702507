import configJson from './config.json';

export default {
  AUTH: {
    LOGOUT_URL: configJson.AUTH.LOGOUT_URL || process.env.REACT_APP_AUTH_LOGOUT_URL,
  },
  FORTNOX: {
    CLIENT_ID: configJson.FORTNOX_CLIENT_ID || process.env.REACT_APP_FORTNOX_CLIENT_ID,
    SCOPE: configJson.FORTNOX_SCOPE || process.env.REACT_APP_FORTNOX_SCOPE,
  },
  VISMAEACCOUNTING: {
    AUTH_URL: configJson.VISMAEACCOUNTING_AUTH_URL || process.env.REACT_APP_VISMAEACCOUNTING_AUTH_URL,
    CLIENT_ID: configJson.VISMAEACCOUNTING_CLIENT_ID || process.env.REACT_APP_VISMAEACCOUNTING_CLIENT_ID,
    SCOPE: configJson.VISMAEACCOUNTING_SCOPE || process.env.REACT_APP_VISMAEACCOUNTING_SCOPE,
  },
  PROCOUNTOR: {
    AUTH_URL: configJson.PROCOUNTOR_AUTH_URL || process.env.REACT_APP_PROCOUNTOR_AUTH_URL,
    CLIENT_ID: configJson.PROCOUNTOR_CLIENT_ID || process.env.REACT_APP_PROCOUNTOR_CLIENT_ID,
  },
  VISMAECONOMIC: {
    CLIENT_ID: configJson.VISMAECONOMIC_CLIENT_ID || process.env.REACT_APP_VISMAECONOMIC_CLIENT_ID,
  },
  VISMADINERO:{
    AUTH_URL: configJson.VISMADINERO_AUTH_URL || process.env.REACT_APP_VISMADINERO_AUTH_URL,
    CLIENT_ID: configJson.VISMADINERO_CLIENT_ID || process.env.REACT_APP_VISMADINERO_CLIENT_ID,
    SCOPE: configJson.VISMADINERO_SCOPE || process.env.REACT_APP_VISMADINERO_SCOPE,
  },
  BASE_URL: configJson.BASE_URL || process.env.REACT_APP_BASE_URL,
  CUSTOMER_API_HOST: configJson.CUSTOMER_API_HOST || process.env.REACT_APP_CUSTOMER_API_HOST,
  FILEONE_PORTAL: configJson.FILEONE_PORTAL || process.env.REACT_APP_FILEONE_PORTAL,
};

import { FormEvent, useState } from "react";
import { Button, Typography } from "@mui/material";
import styles from "./SelectSystemPaper.module.scss";
import supportedSystems from "../../../constants/SupportedSystems";
import { SystemButton } from "../SystemButton";

type SelectSystemsPaperProps = {
  readonly handleNext: (system: string) => void;
  systems: string[];
  readonly text: {
    readonly logo: string | null;
    readonly choseSystem: string | null;
    readonly nextButton: string | null;
  };
};
export const SelectSystemPaper = ({
  handleNext,
  systems,
  text,
}: SelectSystemsPaperProps) => {
  const [selectedSystem, setSelectedSystem] = useState<string>("");

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!selectedSystem) return;

    handleNext(selectedSystem);
  };

  const handleSystemSelect = (key: string) => () => {
    setSelectedSystem(key);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ fontWeight: "bold" }}
      >
        {text.choseSystem}
      </Typography>
      <div className={styles.sourceSelector}>
        {Object.entries(supportedSystems)
          .filter(([, value]) => systems.includes(value.key))
          .map(([key, value]) => (
            <SystemButton
              key={key}
              value={key}
              system={value}
              checked={selectedSystem === key}
              onChange={handleSystemSelect(key)}
            />
          ))}
      </div>
      <Button
        type="submit"
        variant="contained"
        size="large"
        className={styles.btnPrimary}
        disabled={!selectedSystem}
        fullWidth
      >
        {text.nextButton}
      </Button>
    </form>
  );
};
